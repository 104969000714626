/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import pluralize from 'pluralize'

import SkeletonLoading from '../SkeletonLoading/Loadable'
import CardWrapper from '../CardWrapper/Loadable'

import ViolationCountRankingStyled from './styledComponent'
import MESSAGE from './message'

const THIRD_RANK_INDEX = 2

class ViolationCountRanking extends React.PureComponent {
  getRankLabel(label, index) {
    return (
      <div className="rank-label text-style" id={`lbl-${label.toLowerCase()}-${this.getRank(index)}`}>
        {this.getMessage(label.toLowerCase())}
      </div>
    )
  }

  getRank(index) {
    const rankLists = ['1st', '2nd', '3rd']
    return rankLists[index]
  }

  renderDivider(index) {
    let output = null
    if (index < THIRD_RANK_INDEX) {
      output = <div className="divider" />
    }
    return output
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getRankViolationRow(row, index) {
    return (
      <React.Fragment key={`${row.camera_id}-index`}>
        <div className="rank-row">
          <div className="rank-wrapper">
            {this.getRankLabel('RANK', index)}
            <div className="rank-data" id={`lbl-${this.getRank(index)}`}>
              {this.getRank(index)}
            </div>
          </div>
          <div className="camera-wrapper">
            {this.getRankLabel('CAMERA')}
            <Tooltip title={row.camera_name}>
              <div className="camera-data text-style prevent-text-overflow" id={`lbl-camera-data-${this.getRank(index)}`}>
                {row.camera_name}
              </div>
            </Tooltip>
          </div>
          <div className="total-wrapper">
            <div>
              <span className="count-wrapper prevent-text-overflow">{row.violation_count}</span>
              <span className="classifier-wrapper prevent-text-overflow">{pluralize(this.getMessage('count'), row.violation_count)}</span>
            </div>
          </div>
        </div>
        {this.renderDivider(index)}
      </React.Fragment>
    )
  }

  getRankViolationCount() {
    const { data } = this.props
    return (
      <div className="violation-ranking">
        {data.map((rank, index) => {
          return this.getRankViolationRow(rank, index)
        })}
      </div>
    )
  }

  getViolationRankingContent() {
    return (
      <CardWrapper cardTitle={this.props.title} className="count-rank-card">
        <div className="violation-ranking-wrapper">{this.getRankViolationCount()}</div>
      </CardWrapper>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getViolationRankingContent()
    return content
  }

  render() {
    return <ViolationCountRankingStyled className={this.props.className}>{this.getContent()}</ViolationCountRankingStyled>
  }
}

ViolationCountRanking.defaultProps = {
  language: 'EN',
  isLoading: false
}

ViolationCountRanking.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isLoading: PropTypes.bool,
  language: PropTypes.string,
  className: PropTypes.string
}

export default ViolationCountRanking
