const MESSAGE = {
  EN: {
    rank: 'RANK',
    camera: 'CAMERA',
    count: 'COUNT'
  },
  TH: {
    rank: 'อันดับ',
    camera: 'ชื่อกล้อง',
    count: 'ครั้ง'
  }
}

export default MESSAGE
