import styled from 'styled-components'

export default styled.div`
  width: 100%;
  flex: 1;
  .count-rank-card {
    height: 100%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    .violation-ranking-wrapper {
      height: calc(100% - 40px); /* 40px from header */
      background: white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .violation-ranking {
        height: 100%;
        display: flex;
        flex-direction: column;
        .rank-row {
          height: 84px;
          display: grid;
          grid-template-columns: 20% 30% 50%;
          grid-template-rows: 100%;
          .rank-wrapper {
            margin-top: 0.5;
            margin-left: 20px;
          }
          .rank-wrapper,
          .camera-wrapper {
            align-self: center;
            .rank-label {
              transform: rotate(-360deg);
              opacity: 0.5;
              font-family: 'Prompt', sans-serif;
              font-weight: bold;
              font-size: 10px;
              line-height: 0.63;
              color: ${(props) => props.theme.darkBackground};
              margin-bottom: 6px;
            }
            .rank-data,
            .camera-data {
              color: ${(props) => props.theme.darkBackground};
              font-family: 'Prompt', sans-serif;
              font-weight: bold;
              font-size: 24px;
            }
            .camera-data {
              max-width: 160px;
            }
          }
          .total-wrapper {
            justify-self: right;
            margin-right: 20px;
            align-self: center;
            font-family: 'Prompt', 'sans-serif';
            font-size: 10px;
            line-height: 0.63;
            div {
              .count-wrapper {
                font-family: 'Prompt', 'sans-serif';
                font-size: 54px;
                font-weight: bold;
                color: ${(props) => props.theme.orangeFont};
                padding-right: 10px;
                max-width: 150px;
              }
            }
            .classifier-wrapper {
              font-family: 'Prompt', sans-serif;
              font-size: 10px;
              font-weight: bold;
            }
          }
        }
        .divider {
          height: 1px;
          width: 96.03%; // Width is 505px, if want to padding left, right 10px
          opacity: 0.5;
          background-color: ${(props) => props.theme.greyDisabled};
          align-self: center;
        }
      }
    }
  }
`
